import React from 'react';
import styled from 'styled-components';
import ConditionalLayout from '../components/ConditionalLayout';

const Triangle1 = styled.h2`
  position: absolute;
  width: 0;
  height: 0;
  z-index: -2;
  border-bottom: 50vh solid #99eaff;
  bottom: 0;
  border-right: 50vw solid transparent;
  border-bottom-width: 20vh;
  border-right-width: 50vw;
`;
const Triangle2 = styled.h2`
  position: absolute;
  width: 0;
  height: 0;
  z-index: -2;
  border-top: 20vh solid #00aa00;
  border-left: 75vw solid transparent;
  right: 0;
  border-top-width: 40vh;
  border-left-width: 70vw;
  margin-right: 20px;
`;
const Business = () => (
  <ConditionalLayout>
    <Triangle2 />
    <h1>フリー配送</h1>
    <p>
      小山商事ではフリー配送をメインにした運送会社であり、経験豊富なドライバーの技術とスピーディな対応でお客様からお預かりしたお荷物を安心、安全をセットにお届けします！フリー便とは、決まったお客様の専属便、決まった荷物やルートを配送したりする定期便と異なり、毎日違う荷主様の様々な荷物を運んでいます。
    </p>
    <p>
      時にはスポット対応として、他の定期便や専属便において、人手不足などが発生した場合には応援として対応することもあります。フリー便のドライバーからすると、毎日違う荷主様で違う場所へ荷物を運ぶ形になりますので、毎回楽しみな部分もあるようです。毎日、色々な荷物を配送しているので、どんな荷物にも柔軟に対応可能です！！
    </p>
    <Triangle1 />
  </ConditionalLayout>
);

export default Business;
